import React from 'react'
import { Box, FormHelperText, TextField, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Styles } from '../../../../types'
import { tokens } from '../../../../styles/materialTheme'
import {
  REACT_APP_PAYONACCOUNT_MIN_PAYMENT_AMOUNT,
  REACT_APP_PAYONACCOUNT_MAX_PAYMENT_AMOUNT,
} from '../../../../envVariables'

const styles: Styles = {
  textContainer: (theme) => ({
    '.MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '100%',
    },
    width: 'calc(100% - 4rem)',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  fieldContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
    },
  }),
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
}

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

interface PaymentAmountProps {
  handleChange: (amount: number) => void
  label?: string
  placeholder?: string
  errorKey?: string
}

const PaymentAmount = ({
  handleChange,
  label = 'Invoicing.PayOnAccountPage.PaymentAmount',
  placeholder = '',
  errorKey = '',
}: PaymentAmountProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Box sx={styles.fieldContainer}>
      <StyledLabel htmlFor="paymentAmount">
        {t(label)}
        <span className="sr-only">{t('srOnlyRequired')}</span>
      </StyledLabel>
      <Controller
        name="paymentAmount"
        control={control}
        defaultValue=""
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <Box sx={styles.textContainer}>
            <TextField
              id="paymentAmount"
              inputRef={ref}
              type="text"
              placeholder={placeholder}
              value={value}
              error={!!error}
              inputProps={{
                'aria-describedby': 'paymentAmountErrorText',
                'data-testid': 'Inp_PayOnAccount_PaymentAmount',
              }}
              onChange={(e) => {
                onChange(e)
                handleChange(+e.target.value)
              }}
            />
            {error && (
              <FormHelperText
                id="paymentAmountErrorText"
                data-testid="Error_PayOnAccount_PaymentAmount"
                sx={styles.requiredError}>
                {error.message}
              </FormHelperText>
            )}
          </Box>
        )}
        rules={{
          validate: (value) => {
            const isValidInput = /^(0|[1-9]\d*)(\.\d+)?$/.test(value.toString()) // Only positivie, decimal numbers
            const isValidCurrency = /^\d*\.?\d{0,2}$/.test(value.toString()) // no more than two decimal points
            const isLessThanMinAmount =
              parseFloat(value) < REACT_APP_PAYONACCOUNT_MIN_PAYMENT_AMOUNT // minimum that can be paid for pay on account
            const isMoreThanMaxAmount =
              parseFloat(value) > REACT_APP_PAYONACCOUNT_MAX_PAYMENT_AMOUNT // maximum that can be paid for pay on account

            if (value.length === 0) {
              if (errorKey !== '') {
                return `${t(errorKey)}`
              }
              return `${t('Invoicing.MinPaymentAmountRequired', {
                amount: REACT_APP_PAYONACCOUNT_MIN_PAYMENT_AMOUNT,
              })}`
            }
            if (value.length > 0 && !isValidInput) {
              if (errorKey !== '') {
                return `${t(errorKey)}`
              }
              return `${t('Invoicing.PayOnAccountPage.InvalidCharacters')} ${t(
                'Invoicing.MinPaymentAmountRequired',
                {
                  amount: REACT_APP_PAYONACCOUNT_MIN_PAYMENT_AMOUNT,
                },
              )}`
            }
            if (!isValidCurrency) {
              if (errorKey !== '') {
                return `${t(errorKey)}`
              }
              return t('ValidCurrency').toString()
            }
            if (value.length > 0 && isLessThanMinAmount) {
              return `${t('Invoicing.MinPaymentAmountRequired', {
                amount: REACT_APP_PAYONACCOUNT_MIN_PAYMENT_AMOUNT,
              })}`
            }
            if (value.length > 0 && isMoreThanMaxAmount) {
              return `${t('Invoicing.MaxPaymentAmountRequired', {
                amount:
                  REACT_APP_PAYONACCOUNT_MAX_PAYMENT_AMOUNT.toString().replace(
                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                    ',',
                  ),
              })}`
            }
            return true
          },
        }}
      />
    </Box>
  )
}

export default PaymentAmount
